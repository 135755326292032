/* eslint-env browser */

import throttle from 'lodash/throttle';

const timestamp = () => new Date().getTime();

/**
 * @property {number} pingInterval - Interval to send ping.
 * @property {number} updateInterval - Interval to update activity
 * @property {string[]} windowEvents - window events
 * @property {string[]} bodyEvents - body events
 * @property {string} containerQuery - container query selector
 */
const DEFAULT_CONFIG = {
  pingInterval: 5000,
  updateInterval: 500,
  windowEvents: ['focus', 'resize', 'scroll'],
  bodyEvents: ['beforeunload', 'focus', 'mousemove', 'mousedown', 'keydown'],
  containerQuery: 'body',
};

function pixelsSeen(element) {
  const rect = element.getBoundingClientRect();
  const seen = (element.offsetHeight - rect.bottom) + window.innerHeight;

  // No pixels have been seen yet
  if (seen <= 0) {
    return 0;
  }

  return Math.min(seen, element.offsetHeight);
}

export default class ActivityTimePlugin {
  constructor(tracker, config = {}) {
    this.tracker = tracker;
    this.config = Object.assign({}, DEFAULT_CONFIG, config);
    this.throttledUpdate = throttle(() => this.update(), this.config.updateInterval);
    this.start();
  }

  start() {
    this.pingCount = 0;
    this.lastTimeActive = timestamp();
    this.lastTimeReported = timestamp();
    this.scrollPosition = 0;
    this.containerViewPercentage = 0;
    this.pingIntervalRef = setInterval(() => this.ping(), this.config.pingInterval);
    this.bind();
  }

  stop() {
    this.lastTimeActive = undefined;
    this.lastTimeReported = undefined;
    this.scrollPosition = undefined;
    this.containerViewPercentage = undefined;
    clearInterval(this.pingIntervalRef);
    this.unbind();
  }

  ping() {
    if (this.lastTimeActive > this.lastTimeReported) {
      this.pingCount += 1;
      const event = Object.assign({}, this.config.event, {
        duration: this.pingCount * this.config.pingInterval,
        scrollPosition: this.scrollPosition,
        object: {
          custom: {
            'spt:articleViewedPercentage': this.containerViewPercentage,
          },
        },
      });
      this.tracker.track('engagementEvent', event);
      this.lastTimeReported = timestamp();
    }
  }

  updateScrollPosition() {
    const scrollPosition = Math.max(
      0,
      Math.round(window.pageYOffset || document.scrollTop) - (document.clientTop || 0),
    );
    if (scrollPosition > this.scrollPosition) {
      this.scrollPosition = scrollPosition;
    }

    const container = document.querySelector(this.config.containerQuery) || document.body;
    const containerViewPercentage = Math.round((100 / container.offsetHeight)
        * pixelsSeen(container));
    if (containerViewPercentage > this.containerViewPercentage && containerViewPercentage <= 100) {
      this.containerViewPercentage = containerViewPercentage;
    }
  }

  update() {
    this.updateScrollPosition();
    this.lastTimeActive = timestamp();
  }

  bind() {
    if (this.config.windowEvents) {
      this.config.windowEvents.forEach((eventName) => {
        window.addEventListener(eventName, this.throttledUpdate);
      });
    }
    if (this.config.bodyEvents) {
      this.config.bodyEvents.forEach((eventName) => {
        document.body.addEventListener(eventName, this.throttledUpdate);
      });
    }
  }

  unbind() {
    if (this.config.windowEvents) {
      this.config.windowEvents.forEach((eventName) => {
        window.removeEventListener(eventName, this.throttledUpdate);
      });
    }
    if (this.config.bodyEvents) {
      this.config.bodyEvents.forEach((eventName) => {
        document.body.removeEventListener(eventName, this.throttledUpdate);
      });
    }
  }

  restart() {
    this.stop();
    this.start();
  }
}
